const axios = require('axios')

// eslint-disable-next-line import/prefer-default-export
export const fetchClientToken = (isStoreCart = false) =>
  axios
    .get(`${process.env.GATSBY_SYNCHRONY_SERVICE_URL}/token${isStoreCart ? '?scart=true' : ''}`)
    .then(response => response.data)

export const fetchClientTokenStatus = body =>
  axios.post(`${process.env.GATSBY_SYNCHRONY_SERVICE_URL}/tokenStatus`, body).then(response => response.data)
