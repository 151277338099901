import { store } from '@redux/store'
import { getFromBrowserStorage } from '@helpers/storage'
import { updatePayment } from '@services/checkout'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import { getOrder } from '../global'
import { checkoutStepAnalytics } from '../../google-tag-manager'
import { setCheckoutReviewStep } from '../local-storage'

const getAffirmLineItems = (lineItems = []) => {
  const affirmItems = []
  lineItems.forEach(item => {
    affirmItems.push({
      display_name: item?.title ?? 'unknown',
      qty: item?.quantity,
      sku: item?.sku,
      unit_price: Math.floor((item?.unitPrice ?? 0) * 100), // prices are needed in cents, not dollars
    })

    if (item?.warrantyEnabled && item?.warrantyPrice) {
      affirmItems.push({
        display_name: `${item.warrantyPrice.provider ?? ''} ESC for SKU: ${item?.sku}`,
        qty: item?.quantity,
        sku: item.warrantyPrice?.planName ?? 'ESC',
        unit_price: Math.floor((item.warrantyPrice?.price ?? 0) * 100), // prices are needed in cents, not dollars
      })
    }
  })
  return affirmItems
}

export const getAffirmBody = () => {
  // Shipping & Tax amounts should be provided in dollars, but Total and line item Unit Prices, should be in cents
  const order = getOrder()
  let body = null
  if (order) {
    const { billingAddress, contact, lineItems, orderId, payer, paymentInfo, shippingAddress } = order
    const appliedGiftCardAmount =
      paymentInfo?.reduce((sum, p) => sum + (p?.paymentType === 'GIFT' ? p?.authorizedAmount : 0), 0) ?? 0
    const total = Math.floor((order?.total - appliedGiftCardAmount) * 100)

    const shipping = {
      address: {
        line1: shippingAddress?.address1 ?? '',
        line2: shippingAddress?.address2 ?? '',
        city: shippingAddress?.city ?? '',
        state: shippingAddress?.state ?? '',
        zipcode: shippingAddress?.zip ?? '',
      },
      email: contact?.email ?? '',
      name: { first: contact?.firstName ?? '', last: contact?.lastName ?? '' },
      phone_number: contact?.phone ?? '',
    }

    body = {
      billing: !payer?.billingDifferent
        ? { ...shipping }
        : {
            address: {
              line1: billingAddress?.address1 ?? '',
              line2: billingAddress?.address2 ?? '',
              city: billingAddress?.city ?? '',
              state: billingAddress?.state ?? '',
              zipcode: billingAddress?.zip ?? '',
            },
            email: contact?.email ?? '',
            name: { first: payer?.firstName ?? '', last: payer?.lastName ?? '' },
            phone_number: contact?.phone ?? '',
          },
      currency: 'USD',
      discounts: {},
      items: getAffirmLineItems(lineItems),
      merchant: {
        user_cancel_url: window.location.href,
        user_confirmation_url: window.location.href,
        user_confirmation_url_action: 'GET',
      },
      order_id: orderId,
      shipping,
      shipping_amount: order.totalDeliveryCharge,
      tax_amount: order.tax,
      total,
    }
  }

  return body
}

export const applyAffirmPayment = affirmToken => {
  const order = getFromBrowserStorage('session', 'order') ?? {}
  const { orderId = '', paymentInfo = [] } = order

  if (affirmToken && orderId) {
    const newPaymentInfo = [
      ...paymentInfo, // this preserves existing Gift Card payments
      { paymentType: 'AFF', paymentProperties: { checkout_token: affirmToken, order_id: orderId } },
    ]

    updatePayment({ orderId, paymentInfo: newPaymentInfo })
      .then(data => {
        store.dispatch(setOrder(data))
        checkoutStepAnalytics('review')
        setCheckoutReviewStep()
        store.dispatch(setCheckoutStep('review'))
        // remove from local storage, although the checkout step is review
        try {
          // scroll to payment/review section after adding Affirm successfully
          if (window && window.scroll && document.getElementById('review')) {
            const reviewElement = document.getElementById('review')
            const reviewTop = reviewElement?.getBoundingClientRect()?.top ?? 0
            const scrollTop = window.scrollY + reviewTop - 275
            window.scroll({ top: scrollTop, behavior: 'smooth' })
          }
        } catch (e) {
          console.error('Page: Checkout | scroll error', e)
        }
      })
      .catch(() => {
        console.error('Error loading Affirm Payment')
      })
  }
}
