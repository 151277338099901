import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bool, object, func, string } from 'prop-types'
import classNames from 'classnames'
import scriptLoader from 'react-async-script-loader'
import styled from 'styled-components'
import { Box, Button, DialogActions, DialogTitle, DialogContent, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { breakPoints, colors } from '@constants/styles'
import { sentryLogger, levels, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { setOrder } from '@redux/modules/checkout'
import { getToken, updatePayment } from '@services/checkout'
import { getOrder, removeMultiTenderPayment } from '@helpers/checkout/global'
import { submitBillingAddress, validateBillingAddress } from '@helpers/checkout/payment-section/billing-address'
import { scrollTo } from '@helpers/checkout/payment-section/credit-card'
import loaderLight from '@assets/images/loader-light.svg'
import loadingSvg from '@assets/images/loader-dark.svg'
import ScrollSneak from '@helpers/scroll-sneak'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import BillingAddress from './billing-address/billing-address'
import CardExpirationDate from './credit-card/card-expiration-date'
import MultiTenderOption from './credit-card/multi-tender-option'
import MultiTenderPaymentsApplied from './credit-card/multi-tender-payments-applied'
import { DialogDivider, LoadingSpinner } from './styles'

const StyledCreditCardMicroform = styled.div`
  #card-expiration {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .microform-inner-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: ${breakPoints.small}) {
      padding: 0.5rem;
    }
    .microform-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .payment-header {
        padding-left: 0;
        display: block;
        color: black;
        font-size: ${breakPoints.txtMediumUp};
        @media only screen and (max-width: ${breakPoints.small}) {
          padding: 0;
          font-size: ${breakPoints.txtMedium};
        }
      }
    }
    .invalid-card {
      font-size: 0.85rem;
      margin-top: 0.5rem;
      color: ${colors.red};
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: ${breakPoints.small}) {
        margin-top: 0.1rem;
        flex-grow: 1;
      }
      &.hidden {
        visibility: hidden;
      }
    }
    overflow: hidden;
    &.hidden {
      display: none;
    }
    .microform-form-container {
      display: flex;
      flex-direction: column;
    }
    iframe {
      height: 53px !important;
      border: 1px solid ${colors.lightGrey} !important;
      border-radius: 4px;
      padding: 10px;
    }
    .card-input {
      width: 49%;
      &.card-number {
        width: 100%;
      }
      label {
        font-size: 12px;
      }
      input,
      select {
        width: 49%;
        border: 1px solid ${colors.lightGrey};
        padding: 10px;
        font-size: 16px;
      }
      select {
        font-size: 16px;
        height: 53px;
      }
    }
  }

  .loading-icon {
    height: 4em;
    width: 100%;
    background-image: url(${loadingSvg});
    background-repeat: no-repeat;
    background-position: center;
  }
  .error-icon {
    display: flex;
    background-color: ${colors.red};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
`
const scrollSneak = new ScrollSneak('CreditCardMicroform')

const microformOptions = {
  styles: {
    input: { 'font-size': '16px', color: '#333' },
    ':disabled': { cursor: 'not-allowed' },
    valid: { color: '#00854a' },
    invalid: { color: '#a94442' },
  },
}

const getCardBillingAddress = ({ billingAddress, contact, payer, shippingAddress }) => {
  if (payer?.billingDifferent) {
    const { firstName, lastName } = payer
    const { email, phone: phoneNumber } = contact
    const { address1: street1, address2: street2, city, country_code: country, state, zip: postalCode } = billingAddress
    return { firstName, lastName, city, country, email, postalCode, phoneNumber, state, street1, street2 }
  }

  const { email, firstName, lastName, phone: phoneNumber } = contact
  const {
    address1: street1,
    address2: street2,
    city,
    country_code: country,
    county,
    state,
    zip: postalCode,
  } = shippingAddress
  return { firstName, lastName, city, country, county, email, postalCode, phoneNumber, state, street1, street2 }
}

const CreditCardMicroform = ({ isScriptLoaded, isScriptLoadSucceed, onClose, order, testIdPrefix }) => {
  const [errorMessage, setErrorMessage] = useState()
  const [microformIsLoading, setMicroformIsLoading] = useState(!isScriptLoaded)
  const [inputError, setInputError] = useState(null)
  const [didCreateMicroform, setDidCreateMicroform] = useState(false)
  const [submitCard, setSubmitCard] = useState(() => {})
  const [invalidFields, setInvalidFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [secCode, setSecCode] = useState({})
  const [isSplit, setIsSplit] = useState(false)
  const [splitPaymentAmount, setSplitPaymentAmount] = useState('')
  const dispatch = useDispatch()

  const cardRef = useRef()
  const microformRef = useRef()
  const paymentRef = useRef({ isPartial: false, amt: null })
  const existingPayments = useRef(order.paymentInfo)
  const balance = useRef(order.paymentInfo.length ? order.amountDue : order.total)

  const toggleSplit = newIsSplit => {
    setIsSplit(newIsSplit)
    paymentRef.current = { isPartial: newIsSplit, amt: newIsSplit ? paymentRef.current.amt : null }
    if (!newIsSplit) setSplitPaymentAmount('')
  }

  const handleSplitPaymentAmount = amtString => {
    setSplitPaymentAmount(amtString)
    paymentRef.current = { ...paymentRef.current, amt: amtString ? Number(amtString) : null }
  }

  const needsAddressUpdate = order.payer.billingDifferent && !order.payer.billingSubmitted

  const setBillingState = useCallback(
    newState => {
      if (Object.keys(newState).includes('invalidFields')) {
        setInvalidFields(newState.invalidFields)
      }
      if (Object.keys(newState).includes('loading')) {
        setLoading(newState.loading)
      }
    },
    [setInvalidFields, setLoading],
  )

  const submitAddress = useCallback(async () => {
    setLoading(true)
    const newInvalidFields = await validateBillingAddress(order)
    setInvalidFields(newInvalidFields)
    if (newInvalidFields.length < 1) {
      submitBillingAddress(setBillingState)
    } else {
      setLoading(false)
    }
  }, [order, setBillingState])

  const onError = useCallback(
    (errMsg, errorTitle = null, errorDescription = null, errorData = null, level = levels.info) => {
      if (errorTitle && errorDescription) {
        sentryLogger({
          configureScope: {
            type: setExtra,
            error: errorData,
            level,
            order: cleanOrderObject(order),
          },
          captureMessage: {
            type: 'text',
            message: `${errorTitle} - ${errorDescription}`,
            level,
          },
        })
      }
      setErrorMessage(errMsg)
      setMicroformIsLoading(false)
    },
    [order],
  )

  const reloadMicroform = () => {
    const expirationMonth = document.querySelector('#cardExpirationMonth')
    const expirationYear = document.querySelector('#cardExpirationYear')
    expirationMonth.value = ''
    expirationYear.value = ''
    paymentRef.current = { isPartial: true, amt: null }
    setMicroformIsLoading(true)
    setDidCreateMicroform(false)
    setSubmitCard(() => {})
    setSecCode({})
    setIsSplit(true)
    setSplitPaymentAmount('')
    setupMicroform()
  }

  const onSubmitCard = useCallback(() => {
    setInputError(null)
    setMicroformIsLoading(true)

    const expirationMonth = document.querySelector('#cardExpirationMonth')?.value ?? ''
    const expirationYear = document.querySelector('#cardExpirationYear')?.value ?? ''
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const invalidExpDate = Number(expirationYear) === currentYear && Number(expirationMonth) < currentMonth

    const handleTokenResponse = (err, mfResponse) => {
      if (invalidExpDate) {
        setInputError({ details: [{ location: 'expirationYear' }] })
        setMicroformIsLoading(false)
        return
      }

      if (err && err?.details) {
        setInputError(err)
        setMicroformIsLoading(false)
        return
      }

      let paymentInfo = [...(existingPayments.current ?? [])]
      const hasCyberv4 = paymentInfo.some(p => p.paymentType === 'CYBERV4')

      const isPartial = paymentRef.current?.isPartial ?? false
      const paymentAmount = isPartial ? paymentRef.current?.amt ?? null : balance.current

      if (isPartial || hasCyberv4) {
        // combine with any existing CC payments and push a CYBERV4 payment to paymentInfo as multi-tender
        const existingMultiTenderPayments = hasCyberv4 ? paymentInfo.find(p => p.paymentType === 'CYBERV4') : {}
        const appliedIndex = existingMultiTenderPayments?.paymentProperties?.length ?? 0
        const newOrd = getOrder() // needed since the value of the order prop is not updated within this function

        paymentInfo = paymentInfo.filter(p => p.paymentType !== 'CYBERV4')
        paymentInfo.push({
          paymentType: 'CYBERV4',
          paymentProperties: [
            ...(existingMultiTenderPayments?.paymentProperties ?? []),
            {
              authAmount: paymentAmount > balance.current ? balance.current : paymentAmount, // limit the payment amount to max of amountDue
              authorized: false,
              token: mfResponse,
              card: { ...cardRef.current, appliedIndex, expirationMonth, expirationYear },
              billingInfo: getCardBillingAddress(newOrd),
            },
          ],
        })
      } else {
        // push CYBERV3 payment to paymentInfo as single-tender
        const singleTenderCCPayment = {
          paymentType: 'CYBERV3',
          paymentProperties: { card: { ...cardRef.current, expirationMonth, expirationYear }, token: mfResponse },
        }

        const newOrd = getOrder()
        if (newOrd.payer.billingDifferent) {
          singleTenderCCPayment.paymentProperties = {
            ...singleTenderCCPayment.paymentProperties,
            billingInfo: getCardBillingAddress(newOrd),
          }
        }

        paymentInfo.push(singleTenderCCPayment)
      }

      updatePayment({ orderId: order.orderId, paymentInfo })
        .then(newOrder => {
          setMicroformIsLoading(false)
          if (newOrder.paymentInfo) {
            dispatch(setOrder({ ...newOrder, isNonFinanceCredit: true }))
            if (newOrder.amountDue > 0) {
              balance.current = newOrder.amountDue
              existingPayments.current = newOrder.paymentInfo
              reloadMicroform()
            } else {
              onClose()
              scrollTo('payment')
            }
          } else {
            onError('Something went wrong', 'microform updatePayment', 'No paymentInfo found', '', levels.info)
          }
        })
        .catch(error => {
          onError(
            'Something went wrong',
            'microform updatePayment',
            'error when submitting credit card',
            error,
            levels.error,
          )
        })
    }

    microformRef.current.createToken({ expirationMonth, expirationYear }, handleTokenResponse)
  }, [dispatch, onClose, onError, order]) // eslint-disable-line

  const setupMicroform = useCallback(() => {
    getToken({ orderId: order.orderId })
      .then(res => {
        if (res.cybersourcev2Token) {
          const flex = new window.Flex(res.cybersourcev2Token)
          const microformInstance = flex.microform(microformOptions)
          microformRef.current = microformInstance

          if (microformInstance._microformId) {
            const number = microformInstance.createField('number', { placeholder: 'Enter card number' })
            const securityCode = microformInstance.createField('securityCode')

            number.on('change', data => {
              if (data.valid) {
                const { name: cardName, cybsCardType: cardType, securityCode: sCode } = data.card[0]
                cardRef.current = { cardName, cardType }
                setSecCode(sCode)
              }
            })
            number.load('#cardNumber-container')
            securityCode.load('#securityCode-container')

            setMicroformIsLoading(false)
            setDidCreateMicroform(true)
            setSubmitCard(() => () => onSubmitCard())

            /* Token will timeout after 15 mins */
            setTimeout(() => onError('Your session timed out'), 900000) // TODO - manage timeouts for multi-tender
          } else {
            onError('Something went wrong', 'microform creation', 'Could not create microform with provided token')
          }
        } else {
          onError('Something went wrong', 'microform token', 'No microform token provided')
        }
      })
      .catch(err => {
        onError('Something went wrong', 'microform token fetch', 'Could not fetch microform token', err)
      })
  }, [onError, onSubmitCard, order])

  const tryAgain = () => {
    // TODO - is this still needed? It returns user to Delivery step.
    scrollSneak.sneak()
    window.location.reload()
  }

  const parseError = (error, location) => {
    const errLocation = error.details.map(err => err.location).filter(errLoc => errLoc.includes(location))
    let cardErrorMessage
    switch (errLocation[0]) {
      case 'number':
        cardErrorMessage = '* Please enter a valid credit card number.'
        break
      case 'securityCode':
        cardErrorMessage = '* Please enter security code.'
        break
      case 'expirationYear':
        cardErrorMessage = '* Expiration date has passed.'
        break
      default:
        break
    }
    return (
      <Typography variant="caption" color="error">
        {cardErrorMessage}
      </Typography>
    )
  }

  const handleRemovePayment = async paymentIndex => {
    const paymentInfo = removeMultiTenderPayment([paymentIndex], existingPayments.current)

    updatePayment({ orderId: order.orderId, paymentInfo })
      .then(newOrder => {
        setMicroformIsLoading(false)
        if (newOrder.paymentInfo) {
          dispatch(setOrder({ ...newOrder, isNonFinanceCredit: true }))
          balance.current = newOrder.amountDue
          existingPayments.current = newOrder.paymentInfo
          reloadMicroform()
        } else {
          onError('Something went wrong', 'microform updatePayment', 'No paymentInfo found', '', levels.info)
        }
      })
      .catch(error => {
        onError(
          'Something went wrong',
          'microform updatePayment',
          'error when submitting credit card',
          error,
          levels.error,
        )
      })
  }

  useEffect(() => {
    if (isScriptLoaded && !errorMessage) {
      if (isScriptLoadSucceed) {
        if (!didCreateMicroform) {
          setMicroformIsLoading(true)
          setupMicroform()
        }
      } else {
        onError('Something went wrong', 'microform script', 'Could not load microform script')
      }
    }
    scrollSneak.tryToScrollTo('.microform-header')
  }, [didCreateMicroform, errorMessage, isScriptLoaded, isScriptLoadSucceed, onError, setupMicroform])

  const shouldHideForm = errorMessage || microformIsLoading
  const appliedPayments = order?.paymentInfo?.find(p => p.paymentType === 'CYBERV4')?.paymentProperties ?? []

  return (
    <>
      <DialogTitle id="payment-modal-credit__title" component="div" sx={{ p: '12px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <PaymentSvg
            uniqueNameForId="creditMicroForm"
            cards={['discover', 'mastercard', 'visa', 'amex']}
            cordY="0"
            vpHeight="50"
            width="175px"
            height="50px"
          />
          {!shouldHideForm && (
            <Typography
              component="i"
              sx={{ color: '#E11F21', fontSize: 13, fontWeight: 400, fontStyle: 'italic', textTransform: 'capitalize' }}
            >
              * Required
            </Typography>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {!shouldHideForm && (
          <>
            <MultiTenderPaymentsApplied
              payments={appliedPayments}
              removePayment={handleRemovePayment}
              testIdPrefix={testIdPrefix}
            />
            <MultiTenderOption
              amtDue={balance.current}
              isFinalPayment={appliedPayments.length === 3}
              isSplit={isSplit}
              splitAmount={splitPaymentAmount}
              testIdPrefix={testIdPrefix}
              toggleSplit={toggleSplit}
              updateSplitAmt={handleSplitPaymentAmount}
            />
          </>
        )}
        <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
          Enter Credit/Debit Card
        </Typography>
        <div className="billing-iframe">
          <StyledCreditCardMicroform>
            <div className={classNames('microform-inner-container', { hidden: shouldHideForm })}>
              {inputError && (
                <Typography variant="caption" color="error">
                  Please input valid card information.
                </Typography>
              )}
              <div className="microform-form-container">
                <div className="card-input card-number">
                  <label htmlFor="cardNumber-container">
                    Credit/Debit Card Number<span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
                  </label>
                  <div id="cardNumber-container" style={{ borderRadius: 4 }} />
                  {inputError && parseError(inputError, 'number')}
                </div>

                <CardExpirationDate />
                {inputError && parseError(inputError, 'expiration')}

                <div className="card-input">
                  <label htmlFor="securityCode-container">
                    Security Code{secCode?.name ? ` (${secCode.name})` : ''}
                    <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
                  </label>
                  <div id="securityCode-container" className="form-control" />
                </div>
                {inputError && parseError(inputError, 'security')}
              </div>

              <BillingAddress
                clearInvalidFields={() => setInvalidFields([])}
                invalidFields={invalidFields}
                loading={loading}
                order={order}
                setBillingState={setBillingState}
              />
            </div>
            {microformIsLoading && !errorMessage && <div className="loading-icon" />}

            {errorMessage && (
              <Stack direction="row" gap={2} alignItems="center" pt={3}>
                <Box className="error-icon">
                  <CloseIcon htmlColor="white" fontSize="large" />
                </Box>
                <div>{`${errorMessage}. Please try again.`}</div>
              </Stack>
            )}
          </StyledCreditCardMicroform>
        </div>
      </DialogContent>
      {!microformIsLoading && (
        <>
          <DialogDivider />
          <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
            <Stack
              gap={2}
              direction={{ xs: needsAddressUpdate ? 'column-reverse' : 'row', sm: 'row' }}
              sx={{ width: '100%' }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={!loading ? onClose : null}
                data-testid={`${testIdPrefix}close-modal-button`}
              >
                Close
              </Button>
              {needsAddressUpdate ? (
                <Button
                  id="submit-card-btn"
                  aria-label="Verify Billing Address"
                  sx={{ fontSize: '15px', height: '40px' }}
                  fullWidth
                  variant="contained"
                  data-testid={`${testIdPrefix}submit-button`}
                  onClick={errorMessage ? tryAgain : submitAddress}
                  onKeyDown={e => (e.keyCode === 13 ? (errorMessage ? tryAgain : submitAddress) : null)} // eslint-disable-line
                >
                  {loading && <LoadingSpinner alt="Submitting new billing address" src={loaderLight} />}
                  {!loading && errorMessage && 'Try Again'}
                  {!loading && !errorMessage && 'Verify Address'}
                </Button>
              ) : (
                <Button
                  id="submit-card-btn"
                  aria-label={errorMessage ? 'Reload Page' : 'Submit Credit Card for Payment'}
                  sx={{ fontSize: '15px', height: '40px' }}
                  fullWidth
                  variant="contained"
                  data-testid={`${testIdPrefix}submit-button`}
                  disabled={!loading && !errorMessage && isSplit && !splitPaymentAmount}
                  onClick={errorMessage ? tryAgain : submitCard}
                  onKeyDown={e => (e.keyCode === 13 ? (errorMessage ? tryAgain : submitCard) : null)} // eslint-disable-line
                >
                  {loading && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
                  {!loading && errorMessage && 'Try Again'}
                  {!loading && !errorMessage && 'Submit'}
                </Button>
              )}
            </Stack>
          </DialogActions>
        </>
      )}
    </>
  )
}

CreditCardMicroform.propTypes = {
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  onClose: func,
  order: object,
  testIdPrefix: string,
}

export default scriptLoader([`https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js`])(
  CreditCardMicroform,
)
