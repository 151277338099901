import React from 'react'
import { arrayOf, string, bool } from 'prop-types'
import PlaceOrderContext from '../checkout-steps/placeOrderContext'

const ReviewSectionInvalidFields = ({ invalidFields, acceptTermsChecked, placeOrderClicked }) => {
  const acceptTermsAndConditions = `Read and select the checkboxes below to continue.`
  const invalidOrder = 'There was an issue placing your order.'

  const isSubmissionTriedAndInvalid = invalidFields && invalidFields.length > 0 && !invalidFields.includes('order')

  return (
    <>
      {((placeOrderClicked && !acceptTermsChecked) || isSubmissionTriedAndInvalid) && (
        <p className="invalid">{acceptTermsAndConditions}</p>
      )}
      {invalidFields && invalidFields.includes('order') && <p className="invalid">{invalidOrder}</p>}
    </>
  )
}

ReviewSectionInvalidFields.propTypes = {
  invalidFields: arrayOf(string),
  acceptTermsChecked: bool,
  placeOrderClicked: bool,
}

export default ReviewSectionInvalidFields
