import React from 'react'
import { any, array, objectOf, number, bool, string } from 'prop-types'
import { Stack, Typography, styled } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'
import { flatten, uniq } from 'lodash'
import { getDateFull } from '@helpers/date'
import { useTest } from '@hooks/useTest'
import { PriceAtom } from '@rtgdev/design-system'
import { StyledDeliveryCount, StyledDeliveryDate, StyleDeliveryType, StyledGrid } from './styles-mui'
import MiniProductTile from './mini-product-tile'
import CalendarPopupSplit from './delivery-section-calendar-popup-split'
import DistributionCenterPopup from './distribution-center-popup'

const StyledProductContainer = styled(Stack)({
  '& > div': {
    borderBottom: '1px solid #DEDEDE',
    padding: '12px 0',
  },
})

const SplitDelivery = ({
  deliveryCount,
  deliveryOptionString,
  includesExpressFee,
  isDeliveryEditable = true,
  isMobile,
  order,
  shipmentsData,
}) => {
  const { generateTestId } = useTest()
  const lineItemsFlat = uniq(
    flatten(order?.lineItems.map(item => (item.childItems ? [item, ...item.childItems] : item))),
  )
  const storeCartItemImages = {}
  order?.storeCart?.lineItems?.forEach(x => {
    storeCartItemImages[x.sku] = x.imageUrl
  })

  return (
    <>
      {shipmentsData.map((shipment, index) => {
        const fragmentKey = `shipment_${index}`
        return (
          <StyledGrid item xs={12} md={6} key={fragmentKey}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <StyledDeliveryCount>
                DELIVERY {index + 1} OF {deliveryCount}
              </StyledDeliveryCount>
              {isDeliveryEditable && shipment.expressDelivery && (
                <Stack direction="row" alignItems="center">
                  <StyledDeliveryCount>-</StyledDeliveryCount>
                  <BoltIcon htmlColor="#003566" />
                  <StyledDeliveryCount>EXPRESS DELIVERY</StyledDeliveryCount>
                </Stack>
              )}
            </Stack>
            {!isDeliveryEditable && deliveryOptionString && (
              <Stack direction="row" alignItems="center">
                {shipment.expressDelivery && <BoltIcon htmlColor="#003566" />}
                <StyleDeliveryType>
                  {`${shipment.expressDelivery ? 'Express ' : ''}${deliveryOptionString}`}
                </StyleDeliveryType>
              </Stack>
            )}
            <Stack>
              {order.isPickup && (
                <Stack direction="row" spacing={0.5}>
                  <span>Pickup from</span>
                  <DistributionCenterPopup />
                  <span>on:</span>
                </Stack>
              )}
              <StyledDeliveryDate data-testid={generateTestId('Checkout', 'DeliveryDate')}>
                {getDateFull(shipment.deliveryDate, true)}
              </StyledDeliveryDate>
            </Stack>
            {!isMobile && isDeliveryEditable && (
              <CalendarPopupSplit
                index={index}
                initialDate={shipment.deliveryDate}
                order={order}
                shipment={shipment}
                shipmentsData={shipmentsData}
                testId="select-delivery-date-button-split"
                text={order.isPickup ? 'Change Pickup Date' : 'Change Delivery Date'}
              />
            )}
            {isDeliveryEditable && shipment.expressDelivery && includesExpressFee && !isMobile && (
              <Stack direction="row" spacing={0.5}>
                <PriceAtom price={order.premiumExpressFee} />
                <Typography sx={{ maxWidth: 450 }}>Express delivery charge included in total</Typography>
              </Stack>
            )}

            <Stack mt={1}>
              {shipment.items.map((item, i) => {
                const itemsInPackage = item?.product?.items_in_room
                  ?.map(itemInside => ({ ...itemInside, subItems: [] }))
                  .filter(
                    itemInRoom =>
                      shipment.data.skus.includes(itemInRoom.sku) ||
                      shipment.data.skus
                        .map((shipmentSku, iterator) => {
                          if (!itemInRoom.subItems || iterator === 0) {
                            itemInRoom.subItems = []
                          }

                          if (order.packageSkuMap?.[shipmentSku] === itemInRoom.sku) {
                            itemInRoom.subItems.push(
                              lineItemsFlat.filter(lineItem => lineItem.sku === shipmentSku)[0]?.title,
                            )
                            itemInRoom.subItems = uniq(itemInRoom.subItems)
                          }

                          return shipmentSku
                        })
                        .some(shipmentSku => order.packageSkuMap?.[shipmentSku] === itemInRoom.sku),
                  )

                // Build href route based on Regular or Container item
                let route = item?.product?.route ?? null
                if (route && item?.containerSku && item?.childItems?.length) {
                  let params = ''
                  item?.childItems?.forEach((slot, slotIndex) => {
                    params += `"${slot?.slotName}":"${slot?.sku}"`
                    if (slotIndex < item?.childItems?.length - 1) {
                      params += ','
                    }
                  })
                  route += `?slots={${params},"userTouched":true}`
                }

                const parentSkus = [item?.sku, ...(item?.subItems?.map(itm => itm.sku) ?? [])]
                const isParentSkuIncludedInShipment = parentSkus.some(s => shipment.data.skus.includes(s))
                const displayItem =
                  (isParentSkuIncludedInShipment || (!item?.activeAddons && !item?.selections)) &&
                  (item?.product || item.isStoreSku || item.isSelectionItem)

                return (
                  <StyledProductContainer key={item.sku} mt={2}>
                    {displayItem && (
                      <MiniProductTile
                        href={route}
                        image={
                          item?.product?.primary_image ||
                          item?.product?.image ||
                          (item.isStoreSku ? storeCartItemImages[item.sku] : '')
                        }
                        isConfigurable={item?.product?.isConfigurable ?? false}
                        isStoreSku={(item?.product?.isStoreSku ?? item?.isStoreSku) || false}
                        items={itemsInPackage || item?.childItems || []}
                        packageSkuMap={order.packageSkuMap}
                        quantity={item.quantity}
                        requiredAddon={item?.product?.required ?? false}
                        selections={
                          item?.selections?.sku && shipment.data.skus.includes(item.selections.sku)
                            ? item.selections
                            : null
                        }
                        sku={item?.product?.sku || item?.sku}
                        subItems={item?.subItems?.filter(({ sku }) => shipment.data.skus.includes(sku)) || []}
                        title={item?.product?.title ?? item?.title}
                        isContainerItem={item?.containerSku}
                      />
                    )}
                    {item?.activeAddons &&
                      item?.activeAddons
                        .filter(({ sku }) => shipment.data.skus.includes(sku))
                        .map(addonItem => (
                          <MiniProductTile
                            key={addonItem?.sku}
                            href={addonItem?.route}
                            image={addonItem?.image || addonItem?.primary_image}
                            isStoreSku={item.isStoreSku || false}
                            quantity={addonItem?.quantity && addonItem?.quantity * item.quantity}
                            requiredAddon={item?.required}
                            sku={addonItem?.sku}
                            title={addonItem?.title}
                          />
                        ))}
                  </StyledProductContainer>
                )
              })}
            </Stack>
          </StyledGrid>
        )
      })}
    </>
  )
}

SplitDelivery.propTypes = {
  deliveryCount: number,
  deliveryOptionString: string,
  includesExpressFee: bool,
  isDeliveryEditable: bool,
  isMobile: bool,
  order: objectOf(any),
  shipmentsData: array,
}

export default SplitDelivery
