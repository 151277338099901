import React, { useEffect, useState } from 'react'
import { array, bool, func, string } from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { useOrderSummary } from '@components/cart/useOrderSummary'
import { getWellsFargoFinancePlanRegion } from '@helpers/finance'
import { getWellsFargoFinancePlans } from '@services/checkout'

import WellsFargoLogo from '@assets/images/co-wellsfargo.svg'
import WellsFargo from './wells-fargo'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeWellsFargo = ({
  closeModals,
  emailAddress,
  hasBottomBorder = false,
  isOpen,
  openModal,
  orderId,
  paymentInfo,
}) => {
  const [amountDue, setAmountDue] = useState(null)
  const [financePlans, setFinancePlans] = useState()
  const { amtDue, deliveryCost, tax, total } = useOrderSummary()

  useEffect(() => {
    const getPlans = async () => {
      const region = getWellsFargoFinancePlanRegion()
      let plans = await getWellsFargoFinancePlans(region)

      // if region exists and plans is undefined, then try setting the value of plans using the default region with price_zone of "0"
      if (region && !plans) {
        const defaultRegion = `${region.substring(0, 2)}0`
        if (defaultRegion !== region) {
          plans = await getWellsFargoFinancePlans(defaultRegion)
        }
      }

      if (plans) setFinancePlans(plans)
    }

    getPlans()
  }, [])

  useEffect(() => {
    setAmountDue(!_isEmpty(paymentInfo) ? amtDue : total)
  }, [amtDue, paymentInfo, total])

  if (!financePlans) return null // this prevents rendering the WF button until the finance plans are fetched or if no finance plans are available

  return (
    <div>
      <PaymentTypeButton
        ariaId="payment-option-wells-fargo"
        gtmLabel="wells fargo"
        handleClick={() => openModal('wells')}
        hasBottomBorder={hasBottomBorder}
        label="Rooms To Go Credit Card"
        secondaryLabel="Issued by Wells Fargo"
        startIcon={<img src={WellsFargoLogo} width="175px" height="50px" alt="Wells Fargo Card" />}
      />
      {isOpen && (
        <WellsFargo
          amountDue={amountDue}
          closeModals={closeModals}
          downPaymentAmount={deliveryCost + tax}
          emailAddress={emailAddress}
          financePlans={financePlans}
          orderId={orderId}
          paymentInfo={paymentInfo}
        />
      )}
    </div>
  )
}

PaymentTypeWellsFargo.propTypes = {
  closeModals: func,
  emailAddress: string,
  hasBottomBorder: bool,
  isOpen: bool,
  openModal: func,
  orderId: string,
  paymentInfo: array,
}

export default PaymentTypeWellsFargo
