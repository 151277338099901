import React, { useState } from 'react'
import { object } from 'prop-types'
import { Box, Stack, styled } from '@mui/material'
import { useTest } from '@hooks/useTest'
import PaypalButton from './paypal'

const ButtonLabel = styled('span')`
  text-align: 'left';
  display: 'flex';
  width: '100%';
  color: '#333';
  text-transform: 'initial';
  font-weight: 400;
`

const PaymentOption = styled('div')`
  border-top: 1px solid #dedede;
  min-height: 70px;
  display: flex;
`

const StyledStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDisabled',
})(({ isDisabled }) => ({
  padding: '10px 10px 10px 7px',
  width: '100%',
  '&:hover': isDisabled ? null : { backgroundColor: 'rgba(0, 83, 160, 0.04)' },
}))

const PaypalOption = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
  minWidth: '175px',
  height: '55px',
  marginRight: '20px',
  zIndex: 1,
}))

const PaypalPaymentOption = ({ order }) => {
  const [payPalLoaded, setPayPalLoaded] = useState(true)
  const { generateTestId } = useTest()
  const isDisabled = order?.amountDue === 0

  if (!payPalLoaded) return null

  return (
    <PaymentOption>
      <StyledStack direction="row" alignItems="center" isDisabled={isDisabled}>
        <PaypalOption>
          <PaypalButton
            disabled={isDisabled}
            testID={generateTestId('checkout', 'PayPal')}
            isCheckout
            order={order}
            handleScriptFail={() => setPayPalLoaded(false)}
          />
        </PaypalOption>
        <ButtonLabel>Secure Payment Options</ButtonLabel>
      </StyledStack>
    </PaymentOption>
  )
}

PaypalPaymentOption.propTypes = {
  order: object,
}

export default PaypalPaymentOption
