/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react'
import { bool, object, string } from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import { useDispatch } from 'react-redux'
import Recaptcha from 'react-recaptcha'
import useRecaptchaLoader from '@helpers/hooks/useRecaptchaLoader'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { setCheckoutStep as actionCheckoutStep, setOrder } from '@redux/modules/checkout'
import { setCheckoutStep } from '@helpers/checkout/global'
import { updatePayment } from '@services/checkout'
import loaderLight from '../../../../assets/images/loader-light.svg'

const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)

const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITEKEY

const AcimaButton = ({
  customer,
  isDisabled,
  isLoading,
  isMobile,
  isScriptLoaded,
  isScriptLoadSucceed,
  orderId,
  transaction,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [handleButton, setHandleButton] = useState(null)
  const [acimaLoaded, setAcimaLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const acimaMessage = useRef()
  const denied = useRef(false)
  const dispatch = useDispatch()
  const { isWindow, isScriptLoaded: isRecaptchaScriptLoaded } = useRecaptchaLoader()
  const recaptchaRef = useRef(null)
  const recaptchaTokenRef = useRef(null)

  const verifyCallback = token => {
    recaptchaTokenRef.current = token
  }

  const handleSuccessfulLease = paymentProperties => {
    if (isRecaptchaScriptLoaded) recaptchaRef.current.execute()
    setLoading(true)
    updatePayment({ orderId, paymentInfo: [{ paymentType: 'ACIMA', paymentProperties }] })
      .then(finalOrder => {
        dispatch(setOrder({ ...finalOrder, selectedPaymentType: 'acima' }))
        setCheckoutStep(null, 'review', undefined, true, recaptchaTokenRef.current)
      })
      .catch(err => {
        // If Acima checkout completes successfully, but our updatePayment or placeOrder API calls fail to return
        // successfully, then this results in a "No-Charge" order. User would not get an order success email. Backend
        // team will be notified to manually create the order.
        console.error('Error setting payment >> ', err)
      })
      .finally(() => {
        setIsOpen(false)
        setLoading(false)
      })
  }

  const messageEvent = event => {
    const data = typeof event.data === 'string' && (JSON.parse(event.data) ?? false)
    if (data && data?.type !== 'ACIMA_ECOM_IFRAME_RESIZE') {
      if (data?.appStatus === 'Denied') {
        denied.current = true
        if (data?.type === 'ACIMA_ECOM_IFRAME_APPLICATION_STATUS') {
          acimaMessage.current = data
        }
      }
      if (data?.checkoutToken) {
        const { applicationId, checkoutToken, leaseId, leaseNumber } = data ?? {}
        if (applicationId && checkoutToken && leaseId && leaseNumber) {
          handleSuccessfulLease({ applicationId, checkoutToken, leaseId, leaseNumber })
        }
      }
    }
  }

  useEffect(() => {
    if (isScriptLoaded && isMobile) {
      // TODO - Temporary hack to hide the recaptcha badge since it overlaps the Place Order button when Mobile OrderSummary drawer is open.
      // This needs to be fixed since Google requires the badge to be displayed - need to modify CSS of the badge to get it to display
      // at the top of the Swipeable Drawer. More info: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
      const recaptchaBadge = document.getElementById('g-recaptcha')
      if (recaptchaBadge) recaptchaBadge.style.visibility = 'hidden'
    }
  })

  useEffect(() => {
    if (isScriptLoaded && !acimaLoaded) {
      if (isScriptLoadSucceed) {
        setAcimaLoaded(true)
        const options = {
          merchantId: process.env.GATSBY_ACIMA_MERCHANT_ID,
          source: 'other',
          platform: 'custom',
        }

        const handleDenied = () => {
          setLoading(true)
          updatePayment({
            orderId,
            paymentInfo: [
              {
                paymentType: 'ACIMA',
                status: 'Declined',
                paymentProperties: { ...acimaMessage.current },
              },
            ],
          })
            .then(finalOrder => {
              // TODO ACIMA - remove this additional updatePayment call once BE updates the handling of the declined call above so the payment actually gets removed and tax reset
              updatePayment({ orderId, paymentInfo: [] })
                .then(x => {
                  dispatch(setOrder({ ...x, selectedPaymentType: 'Credit' }))
                })
                .finally(() => {
                  dispatch(actionCheckoutStep('payment'))
                })
            })
            .catch(err => {
              console.error('Error resetting payment >> ', err)
            })
            .finally(() => {
              setIsOpen(false)
              setLoading(false)
            })
        }

        const openAcimaModal = () => {
          const acima = new window.Acima.Client(options)

          acima
            .checkout({
              customer,
              transaction,
              isSuccessCallbackMode: false,
              onLeaseAgreementSigned: response => {
                denied.current = false
                acimaMessage.current = response
              },
              onInitialPayment: response => {
                denied.current = false
                acimaMessage.current = response
              },
              onClose: () => {},
            })
            .then(response => {
              acimaMessage.current = response
            })
            .catch(error => {
              if (error?.code !== 'CheckoutInterrupted') {
                console.error('Acima iframe error >>> ', error)
              }
            })
            .finally(() => {
              setIsOpen(false)
              window.removeEventListener('message', messageEvent)
              if (denied.current) {
                handleDenied() // removes payment and returns user to step 3
              }
            })
        }

        setHandleButton(() => event => {
          event.stopPropagation()
          setIsOpen(true)
          openAcimaModal()
          window.addEventListener('message', messageEvent)

          // The following hack is a quick fix for these z-index problems:
          // - in mobile view, the Acima iframe background has a lower z-index than the Mobile Order Summary
          //  drawer that slides up from the footer.
          // - in desktop view, the iframe has lower z-index than the Desktop Header Appbar
          setTimeout(() => {
            let acimaIframe
            let attempts = 0
            while (!acimaIframe && attempts < 100) {
              acimaIframe = document.querySelector('#iframe')
              attempts++ // eslint-disable-line
            }
            if (acimaIframe) {
              acimaIframe.style.zIndex = 2000 // elevates the Acima iframe backdrop above the mobile Order Summary and desktop header appbar
            }
          }, 1000)

          return null
        })
      } else {
        setIsError(true)
      }
    }
    return window.removeEventListener('message', messageEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acimaLoaded, acimaMessage, denied, isScriptLoaded, isScriptLoadSucceed])

  const showSpinner = isLoading || loading

  const handleClick = e => {
    if ((e.type !== 'keydown' || isSpaceOrEnterKey(e.key)) && !showSpinner) {
      if (handleButton) {
        handleButton(e)
      } else {
        setIsOpen(true)
      }
    }
  }

  return (
    <>
      {isWindow && isScriptLoaded && (
        <Recaptcha
          ref={recaptchaRef}
          render="explicit"
          sitekey={SITE_KEY}
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
      <Button
        className="place-order-btn" // not for styling; used as a FullStory selector
        disabled={isDisabled}
        onClick={handleClick}
        onKeyDown={handleClick}
        sx={isMobile ? null : { width: '192px', height: showSpinner ? '62px' : 'unset' }}
        variant="contained"
      >
        {showSpinner ? <img style={{ height: 28 }} alt="Opening Acima Modal" src={loaderLight} /> : 'Place Your Order'}
      </Button>
      {isOpen && (
        // NOTE - the primary purpose of the following Dialog is to place an unscrollable backdrop behind the Acima iframe
        <Dialog
          onClose={(_e, reason) => {
            if (reason !== 'backdropClick') setIsOpen(false)
          }}
          open={isOpen}
          id="payment-modal-acima"
          disableEscapeKeyDown
          fullWidth
          maxWidth="xs"
          PaperProps={{ style: { alignSelf: 'flex-start', margin: '80px 12px 12px 12px', width: 'calc(100% - 24px)' } }}
        >
          <DialogContent>
            {isError ? (
              <Typography variant="subtitle1">
                An error occurred while accessing the Acima Leasing application. <br />
                <br />
                Please try again later or choose a different payment type.
              </Typography>
            ) : (
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                ... Accessing Acima Lease Application
              </Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
            <Button variant="outlined" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

AcimaButton.propTypes = {
  customer: object,
  isDisabled: bool,
  isLoading: bool,
  isMobile: bool,
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  orderId: string,
  transaction: object,
}

export default scriptLoader([process.env.GATSBY_ACIMA_SDK_URL])(AcimaButton)
