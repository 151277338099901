import React, { useEffect, useRef, useState } from 'react'
import { objectOf, any, bool, func, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import scriptLoader from 'react-async-script-loader'
import { Button } from '@mui/material'
import { getOrder } from '@helpers/checkout/global'
import {
  decryptFinancePlans,
  formatCurrency,
  formatNumber,
  getBillingAddress,
  getDbuyStatus,
  getTotal,
  removeDbuyPayment,
  scrollTo,
  updateOrderPayment,
} from '@helpers/checkout/payment-section/digital-buy'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { sentryLogger, levels, sentryMessages, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { getFinancePlansFromRegion, getMerchantID, getLocalStoreCartId } from '@helpers/finance'
import { fetchClientTokenStatus } from '@services/digital-buy'

const DigitalBuy = ({
  closeRtgCreditModal,
  dbuyToken,
  hasError,
  hideAlertModal,
  hideRtgCreditModal,
  isScriptLoaded,
  isScriptLoadSucceed,
  order,
  orderFinancePlanCode,
  resetToken,
  setErrorMsg,
  showAlertModal,
}) => {
  const [processInd, setProcessInd] = useState(1)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const cliNeeded = useRef()
  const cliRejected = useRef()
  const dbuyOpenToBuy = useRef()
  const paymentAccountNumber = useRef()
  const paymentAddress = useRef()
  const paymentAmount = useRef()
  const stateRef = useRef({
    approvedApply: false,
    currentPlan: null,
    currentTotal: 0,
    enableButton: false,
    financeCode: '600',
  })
  const dispatch = useDispatch()

  let dBuyTimeout = null

  const updateTotal = () => {
    const financePlans = getFinancePlansFromRegion() ?? []
    const currentFinancePlan = financePlans?.find(plan => plan?.financeCode === order?.financePlan?.code) ?? {}
    const newFinanceCode = currentFinancePlan
      ? decryptFinancePlans(currentFinancePlan?.financeCode, currentFinancePlan?.encryptedFinanceCodes)
      : ''

    if (currentFinancePlan?.financeCode && currentFinancePlan?.encryptedFinanceCodes) {
      const newTotal = getTotal(!!currentFinancePlan?.downPaymentRequired, order)
      paymentAmount.current = newTotal
      stateRef.current = {
        ...stateRef.current,
        currentPlan: currentFinancePlan,
        currentTotal: newTotal,
        enableButton: !!currentFinancePlan && newFinanceCode.length >= 3,
        financeCode: newFinanceCode,
      }
    }
  }

  useEffect(() => {
    if (isScriptLoaded && !scriptLoaded) {
      if (isScriptLoadSucceed) {
        setScriptLoaded(true)
        clearTimeout(dBuyTimeout)
      } else {
        console.error('Cannot load Digital Buy script!')
        setErrorMsg()
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.fatal,
            order: cleanOrderObject(order),
            paymentType: 'DBUY',
          },
          captureMessage: {
            type: 'text',
            message: `${sentryMessages.digitalBuyFailure} - Cannot load Digital Buy script!`,
            level: levels.fatal,
          },
        })
      }
    }
    updateTotal()
  }, [isScriptLoaded, isScriptLoadSucceed]) // eslint-disable-line

  useEffect(() => {
    const { currentPlan } = stateRef.current
    if (currentPlan?.financeCode !== orderFinancePlanCode) {
      updateTotal()
    }
  }, [orderFinancePlanCode]) // eslint-disable-line

  const closeAllModals = () => {
    hideAlertModal()
    closeRtgCreditModal()
    scrollTo('payment')
  }

  const showRtgCreditModal = () => {
    hideRtgCreditModal(false)
    hideAlertModal()
  }

  const resetClientToken = (msg, skipForceCloseModal = false, closeFn) => {
    if (msg !== false) {
      showAlertModal(msg, closeFn || closeAllModals)
    }

    resetToken() // TODO - can improve this since don't need to set a new token if closeRtgCreditModal() is being called
    setProcessInd(1)
    if (document.getElementById('dbuymodal2')) {
      document.getElementById('dbuymodal2').remove()
    }

    if (!skipForceCloseModal) closeRtgCreditModal()

    return false
  }

  /*
  DBUY modal div IDs:
  - id="dbuymodal1" is the initial modal where user enters card num & CVV
  - id="dbuymodal2" is the next modal with the Accept & Submit button 
  */

  const handleSubmit = async () => {
    const { enableButton } = stateRef.current
    if (enableButton) {
      hideRtgCreditModal(true)
      openSynchronyModal('dbuyform3')
    }
  }

  const openSynchronyModal = async formId => {
    if (processInd === 1) {
      if (document.getElementById('dbuymodal2')) {
        document.getElementById('dbuymodal2').remove()
      }
    }

    if (window && window.syfDBuy) {
      const form = document.getElementById(formId)
      window.syfDBuy.calldBuyProcess(form)
      addToDataLayer_nextgen('synchrony_digital_buy_open')
      window.addEventListener('message', messageEvent)
    }
  }

  const messageEvent = ({ data }) => {
    if (typeof data === 'string') {
      if (data === 'closeModal') {
        // User clicked Cancel button to decline the CLI request, so process payment using the available credit (i.e, OpenToBuy amount)
        cliRejected.current = true
        if (dbuyOpenToBuy.current > 0) {
          showAlertModal('Payment will be applied using your available credit.')
        }
        checkTokenStatusMethod()
      } else if (['Close Model', 'Close Modal', 'Return To Merchant Shipping'].includes(data)) {
        checkTokenStatusMethod()
      }
    }
  }

  const openNextSynchronyModal = availableCredit => {
    const { currentTotal } = stateRef.current
    const newTotal = availableCredit ? formatCurrency(availableCredit, true) : currentTotal
    setProcessInd(2)
    paymentAmount.current = newTotal
    cliRejected.current = false
    stateRef.current = { ...stateRef.current, currentTotal: newTotal }

    setTimeout(
      // this delay is needed to prevent processInd = 2 modal from opening with old data
      () => {
        hideAlertModal(false)
        handleSubmit()
      },
      availableCredit ? 3000 : 500,
    )
  }

  const updatePaymentInfo = clientTokenStatus => {
    dbuyOpenToBuy.current = formatNumber(clientTokenStatus?.OpenToBuyAmount)
    paymentAccountNumber.current = clientTokenStatus?.AccountNumber ?? ''
    paymentAddress.current = getBillingAddress(clientTokenStatus)

    try {
      // add GA event to track when CLI is needed
      const difference = Math.round((dbuyOpenToBuy.current - Number(stateRef.current?.currentTotal)) * 100) / 100
      if (difference < 0) {
        cliNeeded.current = true
        addToDataLayer_nextgen('cli_need', { value: difference })
      } else {
        cliNeeded.current = false
      }
    } catch (error) {
      console.error('Error adding CLI GA event >>> ', error)
    }
  }

  const paymentSessionTimeout = () => {
    // console.log('SETTING TIMER')
    dBuyTimeout = setTimeout(() => {
      const currentOrder = getOrder()
      const hasDBuyPayment = currentOrder.paymentInfo.some(payment => payment.paymentType === 'DBUY')
      // console.log('TIMER EXPIRED >> ', currentOrder, window.location)
      if (currentOrder.orderId && hasDBuyPayment && window.location.pathname.startsWith('/checkout')) {
        removeDbuyPayment(dispatch, order, showAlertModal)
      }
    }, 900000) /* Token will timeout after 15 mins */
    // }, 5000) /* Token will timeout after 5 sec */
  }

  // TODO - paymentSessionTimeout deletes the DBUY payment after 15min, but the alert message via removeDbuyPayment doesn't display to tell customer

  const checkTokenStatusMethod = async () => {
    window.removeEventListener('message', messageEvent)
    let clientTokenStatus
    const { approvedApply, currentPlan, currentTotal, financeCode } = stateRef.current
    try {
      clientTokenStatus = await fetchClientTokenStatus({
        userToken: dbuyToken,
        transactionId: order.orderId,
        ...(!!getLocalStoreCartId() && { scart: true }),
      })
    } catch (error) {
      resetClientToken('Synchrony service is currently unavailable, please try again later.')
      console.error('Error fetching Synchrony token status >>> ', error?.message)
      sentryLogger({
        configureScope: { type: setExtra, level: levels.fatal, order: cleanOrderObject(order), paymentType: 'DBUY' },
        captureMessage: {
          type: 'text',
          message: `${sentryMessages.digitalBuyFailure} - ${error.message}`,
          level: levels.fatal,
        },
      })
    }

    if (clientTokenStatus) {
      const { Address1, responseCode, StatusCode: statusCode } = clientTokenStatus ?? {}
      const dbuyStatus = getDbuyStatus(clientTokenStatus, cliRejected.current)

      switch (dbuyStatus) {
        case 'Account Verified':
          updatePaymentInfo(clientTokenStatus)
          openNextSynchronyModal()
          break

        case 'Invalid CVV':
          resetClientToken('Please verify the card CVV code and try again.', true, showRtgCreditModal)
          break

        case 'Payment Approved': {
          const amt = clientTokenStatus?.TransactionAmount
            ? formatCurrency(clientTokenStatus.TransactionAmount)
            : paymentAmount.current

          const paymentProperties = {
            accountNumber: paymentAccountNumber.current ?? clientTokenStatus?.accountNumber,
            billingAddress: paymentAddress.current,
            financePlan: currentPlan?.financeCode,
            hasPayments: currentPlan?.downPaymentRequired,
            promoCode: financeCode,
            userToken: clientTokenStatus.TokenId,
          }

          showAlertModal('Please wait while we process your payment...')
          const updateSucceeded = await updateOrderPayment(dispatch, amt, paymentProperties, order)
          if (updateSucceeded) {
            if (cliNeeded.current) {
              addToDataLayer_nextgen('cli_accepted')
            }
            paymentSessionTimeout()
            closeAllModals()
          } else {
            showAlertModal('', closeAllModals)
            setTimeout(() => closeAllModals(), 4000)
          }
          break
        }

        case 'CLI Refused': {
          addToDataLayer_nextgen('cli_refused')
          cliNeeded.current = false // needed so that 'Customer Terminated' case won't push the 'refused' event again
          // Check for openToBuyAmount credit
          const creditLimit = dbuyOpenToBuy.current
          if (creditLimit > 0 && creditLimit < currentTotal) {
            openNextSynchronyModal(creditLimit)
          } else if (creditLimit <= 0) {
            // cancelled credit limit increase request but no available credit on the account
            resetClientToken(
              'You do not have enough available credit on this account. Please try another payment method.',
              true,
            )
          } else if (statusCode === '100') {
            resetClientToken(false)
          } else {
            openNextSynchronyModal()
          }
          break
        }

        case 'Customer Terminated': {
          if (cliNeeded.current) {
            addToDataLayer_nextgen('cli_refused')
          }
          closeRtgCreditModal()
          break
        }

        case 'Token Expired': {
          resetClientToken('Your payment session has expired, please try again.', true, showRtgCreditModal)
          break
        }

        case 'Address Verification Failed': {
          if (Address1) {
            updatePaymentInfo(clientTokenStatus)
            if (approvedApply) {
              stateRef.current = { ...stateRef.current, approvedApply: false }
              openNextSynchronyModal()
            } else {
              resetClientToken('Please add or verify the card billing address and try again.', true, showRtgCreditModal)
            }
          } else {
            resetClientToken('Please add or verify the card billing address and try again.', true, showRtgCreditModal)
          }
          break
        }

        case 'eApply Approved': {
          if (Address1) updatePaymentInfo(clientTokenStatus)
          stateRef.current = { ...stateRef.current, approvedApply: true }
          openNextSynchronyModal()
          break
        }

        case 'eApply Declined': {
          resetClientToken(`Your application has been declined, please try another payment method.`)
          break
        }

        case 'Service Unavailable': {
          resetClientToken('Synchrony service is currently unavailable, please try again later.')
          setErrorMsg()
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.fatal,
              order: cleanOrderObject(order),
              paymentType: 'DBUY',
            },
            captureMessage: {
              type: 'text',
              message: `${sentryMessages.digitalBuyFailure} - Digital Buy - SERVICE UNAVAILABLE ${responseCode}!`,
              level: levels.fatal,
            },
          })
          break
        }

        case 'Token Not Found': {
          resetClientToken(`SYNCHRONY ERROR - ${clientTokenStatus?.responseDesc}.`)
          break
        }

        default:
          resetClientToken()
      }
    }
  }

  if (!scriptLoaded || stateRef?.current?.currentTotal === 0 || hasError) {
    return (
      <Button
        fullWidth
        variant="outlined"
        style={{ minWidth: '60%' }}
        disabled
        data-testid="digital-buy-button-disabled"
        aria-label="Synchrony Digital Buy is currently unavailable."
        value="Currently Unavailable"
      >
        Currently Unavailable
      </Button>
    )
  }

  const payerAddress = paymentAddress.current ?? order.shippingAddress
  const dbuyFormInputs = [
    { name: 'processInd', value: processInd },
    { name: 'iniPurAmt', value: paymentAmount.current },
    { name: 'tokenId', value: dbuyToken },
    { name: 'merchantID', value: getMerchantID() },
    { name: 'clientTransId', value: order.orderId },
    { name: 'phoneNumber', value: order.contact.phone },
    { name: 'emailAddress', value: order.contact.email },
    { name: 'custZipCode', value: payerAddress?.zip ?? '' },
    { name: 'custAddress1', value: payerAddress?.address1 ?? '' },
    { name: 'custAddress2', value: payerAddress?.address2 ?? '' },
    { name: 'custCity', value: payerAddress?.city ?? '' },
    { name: 'custState', value: payerAddress?.state ?? '' },
    { name: 'transPromo1', value: stateRef.current?.financeCode },
    { name: 'transAmount1', value: paymentAmount.current },
    { name: 'defaultPromoCode', value: stateRef.current?.financeCode },
  ]

  if (order.contact && order.shippingAddress) {
    return (
      // The following form element with hidden inputs is required for passing data to Synchrony
      <div style={{ minWidth: '45%' }}>
        <form name="dbuyform3" id="dbuyform3">
          {dbuyFormInputs.map(({ name, value }) => (
            <input key={`input_${name}`} type="hidden" name={name} value={value ?? ''} />
          ))}

          <Button
            id="digBuyButton"
            fullWidth
            variant="contained"
            data-toggle="modal"
            data-target="#digBuyModal"
            data-testid="digital-buy-button"
            aria-label="Continue with online application (opens in new window)"
            value="Submit"
            disabled={!stateRef?.current?.enableButton}
            style={{ cursor: !stateRef?.current?.enableButton ? 'default' : 'pointer' }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </div>
    )
  }

  return null
}

DigitalBuy.propTypes = {
  closeRtgCreditModal: func,
  dbuyToken: string,
  hasError: bool,
  hideAlertModal: func,
  hideRtgCreditModal: func,
  order: objectOf(any).isRequired,
  orderFinancePlanCode: string,
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  resetToken: func,
  setErrorMsg: func,
  showAlertModal: func,
}

export default scriptLoader([process.env.GATSBY_SYNCHRONY_DIGITAL_BUY_URL])(DigitalBuy)
